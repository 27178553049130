import React from "react"
import SEO from "../components/seo"
import ListOfCases from "../components/cases/"

const Cases = () => {
  return (
    <>
      <SEO title="Kunden berättar" />
      <ListOfCases />
    </>
  )
}

export default Cases
